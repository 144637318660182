.root {
  &[data-variant='default_black'],
  &[data-variant='default_white'] {
    border-radius: 80px;
    width: 256px;
    height: 56px;
  }

  &[data-variant='connect-form-btn'] {
    border-radius: 18px;
    height: 56px;
  }

  &[data-variant='default_black'] {
    background-color: var(--mantine-color-black);
    color: var(--mantine-color-white);
  }

  &[data-variant='default_white'] {
    background-color: var(--mantine-color-white);
    color: var(--mantine-color-black);
    border: 1px solid var(--mantine-color-black);
  }

  &[data-variant='unstyled'] {
    all: unset;
  }
}
