.root {
  margin: 0;
  &[data-variant='rounded_shadow'] {
    border: 0;
    border-radius: 12px;
    box-shadow: 0px 0px 14.6px 0px #00000040;
    padding: 32px 40px 41px 40px;
  }

  &[data-variant='contact-form'] {
    border: 0;
    background-color: var(--mantine-color-white);
    border-radius: 44px;
  }

  &[data-flex] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
