.root {
  &[data-variant='connect-form-input'] {
    border-radius: 16px;
    height: 52px;
    background-color: var(--mantine-color-background-0);

    .wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .input {
      padding-left: 20px;
      font-size: 15px;

      &[data-error] {
        border: none;
      }
    }
  }
}
